import React, { FC, ReactElement, useContext } from "react";
import * as styles from "./HeroBannerMobile.module.scss";
import CircleOutline from "../CircleOutlineAndBlur/CircleOutline";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";
import moment from "moment";
import momenttz from "moment-timezone";
import { FiCalendar, FiClock } from "react-icons/fi";
import CustomButton from "../CustomButton/CustomButton";
import CircleGreenBlur from "../CircleOutlineAndBlur/Circlegreenblur";
import CustomReportsButton from "../CustomButton/CustomReportsButton";
import { LanguageContext } from "../../../context/languageContext";
import ReportHeroFrom from "../../ReportsUiComponents/ReportHeroForm/ReportHeroFrom";
const BlockContent = require("@sanity/block-content-to-react");

type Props = {
  heading?: string | ReactElement;
  description: string | ReactElement;
  homeBackground?: any;
  buttonText?: string | ReactElement;
  date?: string;
  link?: string;
  linkedIn?: string;
  github?: string;
  caseStudyDescription?: string;
  isExternalLink?: boolean;
  externalLink?: string;
  alt: string;
  isReport?: boolean;
  isNewReport?: string;
  formIntro?: string;
  submitFormMessage?: string;
};

const HeroBannerMobile: FC<Props> = ({
  heading,
  description,
  homeBackground,
  buttonText,
  date,
  link,
  linkedIn,
  github,
  caseStudyDescription,
  isExternalLink,
  externalLink,
  alt,
  isReport = false,
  isNewReport = "No",
  formIntro,
  submitFormMessage,
}) => {
  const { language } = useContext(LanguageContext);
  return (
    <div className={styles.homeMobileWrapper}>
      <div className={styles.headerAndDescription}>
        <h1
          style={{
            overflowWrap: "break-word",
            wordWrap: "break-word",
            hyphens: "auto",
            marginTop: isNewReport === "Yes" ? "40px" : "",
          }}
        >
          {" "}
          {heading}
        </h1>
        <div className={styles.descriptionHeaderWrapper}>
          {date && (
            <p>
              <span>
                <FiCalendar color="#00ECBD" />
                &nbsp; {moment(date).format("MMM Do YYYY")}
              </span>

              <span>
                <FiClock color="#00ECBD" />
                &nbsp;At {moment(date).format("HH:mm")} hrs.{" "}
                {momenttz(date).tz("Europe/Berlin").format("z")}
              </span>
            </p>
          )}
          <span>
            {description}
            {caseStudyDescription && (
              <BlockContent blocks={caseStudyDescription} serializers={{}} />
            )}
          </span>
        </div>

        {linkedIn && (
          <a
            href={linkedIn}
            target="_blank"
            rel="noreferrer"
            className={styles.authorLinkedIn}
          >
            <FaLinkedinIn color="#ffff" size={22} />
          </a>
        )}

        {github && (
          <a
            href={github}
            target="_blank"
            rel="noreferrer"
            className={styles.authorLinkedIn}
          >
            <FaGithub color="#ffff" size={20} />
          </a>
        )}
      </div>

      {buttonText && (
        <>
          {!isReport ? (
            <CustomButton
              text={buttonText}
              image={arrowRightWhite}
              linkPath={link}
              externalLink={externalLink}
            />
          ) : (
            <CustomReportsButton
              text={buttonText}
              image={arrowRightWhite}
              linkPath={link}
              buttonMainClass={
                language === "EN" ? styles.reportBtn : styles.reportBtnGerman
              }
            />
          )}
        </>
      )}

      {isNewReport !== "Yes" ? (
        <div className={styles.backgroundCircles}>
          <CircleOutline
            isBlurVisible={true}
            initialScale={1}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0}
            transitionDuration={0}
            circleStyleClass={styles.heroBackgroundCircleLeft}
          >
            <CircleGreenBlur
              transitionType={"easeIn"}
              transitionDelay={0}
              transitionDuration={0}
              circleStyleClass={styles.heroBackgroundInnerCircleGreen}
            />
          </CircleOutline>
          <img src={homeBackground} alt={alt} style={{ objectFit: "cover" }} />
        </div>
      ) : (
        <div style={{ width: "100%", margin: "0 auto" }}>
          <div className={styles.backgroundCircles} style={{ zIndex: 0 }}>
            <CircleOutline
              isBlurVisible={true}
              initialScale={1}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0}
              transitionDuration={0}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            >
              <CircleGreenBlur
                transitionType={"easeIn"}
                transitionDelay={0}
                transitionDuration={0}
                circleStyleClass={styles.heroBackgroundInnerCircleGreen}
              />
            </CircleOutline>
          </div>
          <div style={{ zIndex: 999999999999999 }}>
            <ReportHeroFrom
              paddingStyle={true}
              formIntro={formIntro}
              submitFormMessage={submitFormMessage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroBannerMobile;
