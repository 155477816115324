import React, { FC, ReactElement } from "react";
import "./ContactInputForm.scss";

type Props = {
  name: string;
  placeHolder: string;
  register: any; //this is passed from import { useForm } from "react-hook-form";
  wrapperClassName: string; //this a wrapper class
  labelInput?: string | ReactElement;
  isRequired?: boolean;
};

//if you need other things just add as optional props here
const ContactInputForm: FC<Props> = ({
  name,
  placeHolder,
  register,
  wrapperClassName,
  labelInput,
  isRequired,
}) => {
  return (
    <div className={wrapperClassName}>
      {name === "email" ? (
        <input
          id={name}
          type="text"
          name={name}
          placeholder={placeHolder}
          {...register(name, {
            required: isRequired,
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Please enter a valid email",
            },
          })}
        />
      ) : (
        <input
          id={name}
          type="text"
          name={name}
          placeholder={placeHolder}
          {...register(name, { required: isRequired })}
        />
      )}
      <label htmlFor={name}>{labelInput}</label>
    </div>
  );
};

export default ContactInputForm;
