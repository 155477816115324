import React, { FC, useContext, useEffect, useState } from "react";
import * as styles from "./ReportHeroForm.module.scss";
import { navigate } from "gatsby";

import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";

import ContactInputForm from "../../Shared/ContactInputForm/ContactInputForm";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import { reportsFields } from "./ReportHeroForm.helper";
import translate from "../../../i18n/translate";
import { LanguageContext } from "../../../context/languageContext";

type Props = {
  pageContext?: any;
  data?: any;
  paddingStyle?: boolean;
  formIntro?: string;
  submitFormMessage?: string;
};

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
};

function isBrowser() {
  return typeof window !== "undefined";
}

const ReportHeroFrom: FC<Props> = ({
  formIntro,
  submitFormMessage,
  paddingStyle = false,
}) => {
  const { width } = UseWindowSize();
  const { language } = useContext(LanguageContext);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = (data: any) => {
    if (!errors.email && !errors.firstName && !errors.lastName) {
      setTimeout(() => {
        reset({});
      }, 100);
      navigate("/reports/thank-you");
    }
  };
  const [languageEnch, setLanguageEnch] = useState("EN");

  useEffect(() => {
    if (isBrowser()) {
      setLanguageEnch(localStorage.getItem("lang"));
    }
  }, []);
  return (
    <div
      className={styles.webinarFormColumnRight}
      style={{ zIndex: 99999999999999 }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          padding: paddingStyle ? "30px" : "30px 40px",
          zIndex: 99999999999999,
        }}
      >
        <span>{formIntro}</span>
        <input
          id={"en-de"}
          type="text"
          hidden={true}
          name={"language"}
          value={languageEnch}
        />
        {reportsFields.map(field => (
          <div key={field.name} className={styles.singleWebinarInput}>
            <ContactInputForm
              name={field.name}
              placeHolder={field.placeholder}
              register={register}
              isRequired={field.require}
              labelInput={field.label}
              wrapperClassName={width > 800 ? styles.field : styles.fieldMobile}
            />
          </div>
        ))}
        <div>
          {errors.email || errors.firstName || errors.lastName ? (
            <span
              style={{
                color: "#ff3333",
                fontFamily: "Poppins",
                fontSize: "13px",
              }}
            >
              {errors.email?.message
                ? "Please enter a valid email"
                : `Please fill all required fields (*)`}
            </span>
          ) : null}
        </div>
        <div>
          <p>{submitFormMessage}</p>
        </div>

        <motion.button
          style={{
            zIndex: 999999999999999,
            width: language === "EN" ? "250px" : "280px",
          }}
          initial={{ opacity: 0, visibility: "hidden" }}
          animate={{ opacity: 1, visibility: "visible" }}
          className={styles.contactButton}
          type="submit"
        >
          <span>{translate("report-download-btn")}</span>
          <motion.img
            height="auto"
            width="auto"
            src={arrowRightWhite}
            alt="contact us"
          />
        </motion.button>
      </form>
    </div>
  );
};

export default ReportHeroFrom;
