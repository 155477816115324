import React, { FC } from "react";
import * as styles from "./customButton.module.scss";
import { Link } from "gatsby";
import cx from "classnames";

type Props = {
  image: any;
  text: string | any;
  type?: any;
  linkPath?: any;
  buttonMainClass?: any;
  buttonClass?: any;
};

const CustomReportsButton: FC<Props> = ({
  image,
  text,
  type,
  linkPath,
  buttonMainClass,
  buttonClass,
}) => {
  return (
    <div className={buttonMainClass}>
      {text !== "Submit" ? (
        <Link to={linkPath}>
          <button className={cx(buttonClass, styles.contactButton)} type={type}>
            <span>{text}</span>
            <img src={image} alt={text} />
          </button>
        </Link>
      ) : (
        <button className={cx(buttonClass, styles.contactButton)} type={type}>
          <span>{text}</span>
          <img src={image} alt={text} />
        </button>
      )}
    </div>
  );
};

export default CustomReportsButton;
