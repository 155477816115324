import translate from "../../../i18n/translate";

export const reportsFields = [
  {
    name: "firstName",
    type: "text",
    label: translate("report-first-name"),
    require: true,
    placeholder: " ",
  },
  {
    name: "lastName",
    type: "text",
    label: translate("report-last-name"),
    require: true,
    placeholder: " ",
  },
  {
    name: "email",
    type: "email",
    label: translate("report-email"),
    require: true,
    placeholder: " ",
  },
];
